<template>
  <DelayHydration>
    <LobbyPage
      :mainRoute="ROUTES_NAME.LOBBY"
      :mainHomeLabel="$t('common.lobby')"
      :displaySectionBanner="DISPLAY_SECTION.IN_LOBBY"
      :topSection="DISPLAY_SECTION.IN_TOP_LOBBY"
      :bottomSection="DISPLAY_SECTION.IN_BOTTOM_LOBBY"
      :navSection="DISPLAY_SECTION.IN_LOBBY_NAV"
      :showExtraSections="true"
      :type="GameType.LOBBY"
    />
  </DelayHydration>
</template>

<script setup lang="ts">
import LobbyPage from "~/components/lobby/LobbyPage.vue";
import { DISPLAY_SECTION } from "~/config/enum";
import { ROUTES_NAME } from "~/config/router";
import {
  useGetBiggestWin,
  useGetGameCategoriesWithGames,
  useGetListImageSliders,
} from "~/queries/gameService";
import { GameType } from "~/types/schema";

definePageMeta({
  layout: "auth",
  middleware: ["fetch-common-api"],
  showLobbyTransaction: true,
});

const token = useToken();
const { suspense: prefetchGameCategories } = useGetGameCategoriesWithGames(
  GameType.LOBBY
);
const { suspense: prefetchSliders } = useGetListImageSliders();
const promise: Promise<any>[] = [];
promise.push(prefetchGameCategories());
promise.push(prefetchSliders());
if (token.value) {
  const { suspense: prefetchBiggestWin } = useGetBiggestWin();
  promise.push(prefetchBiggestWin());
}
await Promise.all(promise);
</script>
